import './App.css';
import Maps from './components/Maps';

function App() {
  return (
    <Maps/>
  );
}

export default App;
