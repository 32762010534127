import React from 'react'
import { AlertTypes } from '../constants/value'

const AlertComponent = ({ data }) => {

  console.log(data)

  const type_alert = AlertTypes.find(t => t.name === data.nature) || null;

  return (
    <div className='flex items-center gap-2 bg-white border p-2 rounded-lg border-gray/20'>
      {type_alert && <span className=''>
        {type_alert.icon}
      </span>}

      <div className=''>
        <h2 className='font-mmedium text-md'>{data.nature}</h2>
        {data?.alert_utilisateur && <p className='font-mlight text-sm'>{data?.alert_utilisateur?.numero_telephone}</p>}
      </div>
    </div>
  )
}

export default AlertComponent