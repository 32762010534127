import React, { useCallback, useEffect, useState } from "react";
import ReactMapGl, { Marker, Source, Layer } from "react-map-gl"

import axios from "axios";
import socket from "../services/socket";
import { BASE_URL } from "../constants/config";
import AlertComponent from "./AlertComponent";
import { AlertTypes } from "../constants/value";

import mapboxgl from 'mapbox-gl'; // Importer mapbox-gl

// Désactiver les Web Workers de Mapbox pour contourner les erreurs liées à la CSP
mapboxgl.workerClass = null;

const Maps = () => {
    const [alerts, setAlerts] = useState([]);
    const [showAlert, setShowAlert] = useState('active');
    const [viewport, setViewport] = useState({
        longitude: -122.4376,
        latitude: 37.7577,
        zoom: 8,
    });

    // Récupérer les alertes via Axios
    const fetchAlerts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/alert/active`);
            setAlerts(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération des alertes :", error);
        }
    };

    useEffect(() => {
        fetchAlerts();

        socket.on('connect', () => {
            console.log('Socket connected:', socket.id);
        });

        socket.on('locationUpdated', (data) => {
            const { alert, position } = data;

            setAlerts(prev => [...prev.map((a, index) => {
                if (a.id === alert.id) {
                    return alert
                } else {
                    return a
                }
            })])

        });

        return () => {
            socket.off('locationUpdated');
            socket.off('connect');
        };
    }, []);

    const data_alert = alerts.filter(a => {
        return showAlert === "active" ? a.active : !a.active;
    })


    // Générer les coordonnées de la ligne pour chaque alerte
    const getLineCoordinates = (alert) => {
        if (!alert || !alert.position_alert || alert.position_alert.length === 0) return [];
        return [
            [alert.longitude_start, alert.latitude_start], // Position de départ
            ...alert.position_alert.map(pos => [pos.longitude, pos.latitude]) // Positions intermédiaires
        ];
    };


    console.log("ALERTes :: ", alerts);

    return (
        <div className="flex">
            <div className="h-screen w-[300px]">
                <div className="flex items-center p-2 bg-primary border-l-4 border-l-secondary">
                    {/* <img src={require('../assets/images/logo-light.png')} alt="logo batela" className="object-contain w-[50px]"/> */}
                    <div>
                        <h2 className="font-mbold text-xl text-light">Batela</h2>
                        <p className="text-md text-light">Admin, interface for emergency center.</p>
                    </div>
                </div>

                <div className="flex">
                    <button onClick={() => setShowAlert('active')} className={`flex flex-1 py-2 justify-center items-center border-b ${showAlert === 'active' ? 'border-b-primary' : 'border-b-white'}`}>Active</button>
                    <button onClick={() => setShowAlert('historique')} className={`flex flex-1 py-2 justify-center items-center border-b ${showAlert === 'historique' ? 'border-b-primary' : 'border-b-white'}`}>Historique</button>
                </div>

                <div className="flex flex-1 overflow-y-scroll h-[75vh] flex-col px-2 my-3 gap-2">
                    {data_alert.map((a, index) => {
                        return <AlertComponent key={index} data={a} />
                    })}
                </div>
            </div>

            <div className="flex-1 h-screen">
                <ReactMapGl
                    // {...viewport}
                    width={"100%"}
                    height={"100%"}
                    mapboxAccessToken={`pk.eyJ1IjoiaWNjY29uZ29hcHAiLCJhIjoiY2x6ZzltemthMWJtZjJxcXo0ZzV1bmNwdCJ9.70_aw8sGRBpgjAaxm30rvw`}
                    transitionDuration={200}
                    mapStyle={"mapbox://styles/mapbox/streets-v12"}
                    scrollZoom={true}
                    dragPan={true}
                    dragRotate={false}
                    touchZoomRotate={true}
                    minZoom={5}
                >
                    {/* Afficher les marqueurs et les lignes pour chaque alerte */}
                    {alerts.filter(a => a.active && a.longitude_start && a.latitude_start && a.position_alert).map((alert) => {

                        const type_alert = AlertTypes.find(t => t.name === alert.nature) || null;


                        return (
                            <Marker
                                key={alert.id}
                                longitude={getLineCoordinates(alert).length > 0 ? getLineCoordinates(alert)[getLineCoordinates(alert).length - 1][0] : alert.longitude_start}
                                latitude={getLineCoordinates(alert).length > 0 ? getLineCoordinates(alert)[getLineCoordinates(alert).length - 1][1] : alert.latitude_start}
                            >
                                {type_alert && (
                                    <span className='flex bg-white justify-center items-center w-[40px] h-[40px] rounded-full border'>
                                        {type_alert.icon}
                                    </span>
                                )}
                            </Marker>

                        )
                    })}
                </ReactMapGl>
            </div>
        </div>
    );
};

export default Maps;
