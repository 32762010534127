import { MdReportProblem, MdSecurity, MdCarCrash, MdWaterDamage, MdBolt, MdLeakAdd, MdPersonSearch } from 'react-icons/md';
import { FaFire, FaBomb } from 'react-icons/fa';
import { IoMdMedkit } from 'react-icons/io';

export const AlertTypes = [
  {
    id: 1,
    name: 'Agression',
    centre: 'Police',
    description: 'Signaler une agression ou un comportement violent',
    icon: <MdReportProblem size={20} color="#6c757d" /> // Placeholder color
  },
  {
    id: 2,
    name: 'Vol',
    centre: 'Police',
    description: 'Signaler un vol ou une tentative de vol',
    icon: <MdSecurity size={20} color="#6c757d" />
  },
  {
    id: 3,
    name: 'Incendie',
    centre: 'Pompier',
    description: 'Signaler un incendie dans votre région',
    icon: <FaFire size={20} color="#6c757d" />
  },
  {
    id: 4,
    name: 'Accident de la route',
    centre: 'Pompier',
    description: 'Signaler un accident de la route',
    icon: <MdCarCrash size={20} color="#6c757d" />
  },
  {
    id: 5,
    name: 'Urgence médicale',
    centre: 'Hôpital',
    description: 'Demander une assistance médicale d’urgence',
    icon: <IoMdMedkit size={20} color="#6c757d" />
  },
  {
    id: 6,
    name: 'Inondation',
    centre: 'Pompier',
    description: 'Signaler une inondation ou une montée des eaux',
    icon: <MdWaterDamage size={20} color="blue" />
  },
  {
    id: 7,
    name: 'Électrocution',
    centre: 'Hôpital',
    description: 'Signaler une électrocution ou un danger électrique',
    icon: <MdBolt size={20} color="yellow" />
  },
  {
    id: 8,
    name: 'Explosion',
    centre: 'Pompier',
    description: 'Signaler une explosion ou un danger imminent',
    icon: <FaBomb size={20} color="black" />
  },
  {
    id: 9,
    name: 'Fuite de gaz',
    centre: 'Pompier',
    description: 'Signaler une fuite de gaz potentiellement dangereuse',
    icon: <MdLeakAdd size={20} color="gray" />
  },
  {
    id: 10,
    name: 'Personne disparue',
    centre: 'Police',
    description: 'Signaler une disparition d’une personne',
    icon: <MdPersonSearch size={20} color="blue" />
  }
];


